import classNames from 'classnames'
import React from 'react'

interface ContainerProps {
  children: React.ReactNode
  theme?: 'dark' | 'light'
  className?: string
}

export default function Container({
  children,
  theme,
  className,
}: ContainerProps) {
  return (
    <div className={classNames('container', className)} data-theme={theme}>
      {children}
    </div>
  )
}
