import React from 'react'
import { Repeater, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import * as styles from 'css/FeatureGrid.module.css'
import classNames from 'classnames'
import { FeatureGridItemProps } from './FeatureGridItem'
import Section from '../shared/components/Section'
import { sectionLayoutSideGroup } from '../sideEditProps'
import { SectionProps } from '../shared/components/Section'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
interface FeatureGridProps {
  items: FeatureGridItemProps[]
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
  backgroundColor: SectionProps['backgroundColor']
}

const FeatureGrid: types.Brick<FeatureGridProps> = ({
  paddingTop,
  paddingBottom,
  backgroundColor
}) => {
  return (
    <EditorBrickContainer>
      <Section container paddingTop={paddingTop} paddingBottom={paddingBottom} backgroundColor={backgroundColor}>
        <div className={classNames('grid', styles.gridContainer)}>
          <Repeater propName="items" />
        </div>
      </Section>
    </EditorBrickContainer>
  )
}

FeatureGrid.schema = {
  name: blockNames.FeatureGrid,
  label: 'Feature Grid',
  previewImageUrl: `/bricks/${blockNames.FeatureGrid}.png`,
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    paddingTop: 'medium',
    paddingBottom: 'medium',
    items: [
      {
        title: 'Cash Offer',
        text: 'Get a cash offer on your home in fast!',
        icon: {
          hashId: 'ZrGcI6UvzgO8Q4W',
          src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/-Uoc4YHPRJAGR5k.webp',
          srcSet:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-96.webp 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-72.webp 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-48.webp 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-24.webp 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-12.webp 12w',
          type: 'image/webp',
          placeholderSrc:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/-Uoc4YHPRJAGR5k.jpg',
          fallbackSrc:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/-Uoc4YHPRJAGR5k.png',
          fallbackSrcSet:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-96.png 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-72.png 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-48.png 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-24.png 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-12.png 12w',
          fallbackType: 'image/png',
          height: 512,
          width: 512,
          alt: '',
          seoName: 'home',
          crop: {
            x: 0,
            y: 0,
            width: 512,
            height: 512,
          },
          transform: {
            rotate: 0,
            flip: {
              horizontal: false,
              vertical: false,
            },
          },
        },
      },
      {
        title: 'Home Appraisal Visit',
        text: "Ensure your home's value with a pre-offer appraisal.",
        icon: {
          hashId: 'aGyzp7L4L7B5twv',
          src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/Xbp13W0E8wDSHX4.webp',
          srcSet:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-96.webp 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-72.webp 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-48.webp 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-24.webp 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-12.webp 12w',
          type: 'image/webp',
          placeholderSrc:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/Xbp13W0E8wDSHX4.jpg',
          fallbackSrc:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/Xbp13W0E8wDSHX4.png',
          fallbackSrcSet:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-96.png 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-72.png 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-48.png 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-24.png 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/Xbp13W0E8wDSHX4-12.png 12w',
          fallbackType: 'image/png',
          height: 512,
          width: 512,
          alt: '',
          seoName: 'search',
          highPriority: false,
          crop: {
            x: 0,
            y: 0,
            width: 512,
            height: 512,
          },
          transform: {
            rotate: 0,
            flip: {
              horizontal: false,
              vertical: false,
            },
          },
        },
      },
      {
        title: 'Receive Payment!',
        text: 'Get paid for your sold home!',
        icon: {
          hashId: '633N15Q7IH8jc52',
          src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/gZO6DYlxlTuDt_3.webp',
          srcSet:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-96.webp 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-72.webp 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-48.webp 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-24.webp 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-12.webp 12w',
          type: 'image/webp',
          placeholderSrc:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/gZO6DYlxlTuDt_3.jpg',
          fallbackSrc:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/gZO6DYlxlTuDt_3.png',
          fallbackSrcSet:
            'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-96.png 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-72.png 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-48.png 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-24.png 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/gZO6DYlxlTuDt_3-12.png 12w',
          fallbackType: 'image/png',
          height: 512,
          width: 512,
          alt: '',
          seoName: 'sign',
          highPriority: false,
          crop: {
            x: 0,
            y: 0,
            width: 512,
            height: 512,
          },
          transform: {
            rotate: 0,
            flip: {
              horizontal: false,
              vertical: false,
            },
          },
        },
      },
    ],
  }),
  repeaterItems: [
    {
      name: 'items',
      itemType: blockNames.FeatureGridItem,
      itemLabel: 'Feature Grid Item',
      min: 1,
      max: 4,
    },
  ],
  // Sidebar Edit controls for props
  sideEditProps: [sectionLayoutSideGroup],
}

export default FeatureGrid
