import React from 'react'
import { types } from 'react-bricks/frontend'
import * as style from 'css/ImageSection.module.css'
import blockNames from '../blockNames'
import { Image } from 'react-bricks/frontend'
import Section from '../shared/components/Section'

interface ImageSectionProps {
  imageUrl: types.IImageSource
  altText: string
  caption: string
}

const ImageSection: types.Brick<ImageSectionProps> = ({
  imageUrl,
  altText,
  caption,
}) => {
  return (
    <Section container>
      <article className={style.imageContainer}>
        <Image
          propName="image"
          alt={altText}
          imageClassName={style.image}
          aspectRatio={16 / 9}
        />
      </article>
      {caption && (
        <small className={style.imageSectionCaption}>{caption}</small>
      )}
    </Section>
  )
}

ImageSection.schema = {
  name: 'imageSection',
  label: 'Image Section',
  previewImageUrl: `/bricks/${blockNames.ImageSection}.png`,
  getDefaultProps: () => ({
    altText: 'Placeholder Image',
    caption: 'This is a placeholder caption.',
  }),
  sideEditProps: [
    {
      name: 'altText',
      label: 'Alt Text',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'caption',
      label: 'Caption',
      type: types.SideEditPropType.Text,
    },
  ],
}

export default ImageSection
