enum blockNames {
  Button = 'button',
  HorizontalHero = 'horizontalHero',
  Header = 'header',
  HeaderMenuItem = 'headerMenuItem',
  HeaderMenuSubItem = 'headerMenuSubItem',
  Footer = 'footer',
  FooterColumn = 'footerColumn',
  FooterLink = 'footerLink',
  TextImage = 'textImage',
  FeatureGrid = 'featureGrid',
  FeatureGridItem = 'featureGridItem',
  Heading = 'heading',
  NavigationCards = 'navigationCards',
  NavigationCard = 'navigationCard',
  TextForm = 'textForm',
  Form = 'form',
  FormField = 'formField',
  CtaBanner = 'ctaBanner',
  TeamMemberContent = 'teamMemberContent',
  Paragraph = 'paragraph',
  AccordionGroup = 'accordionGroup',
  AccordionItem = 'accordionItem',
  FormSection = 'formSection',
  ImageSection = 'imageSection',
  Sidebar = 'sidebar',
}

export default blockNames
