import React from 'react'
import { useAdminContext } from 'react-bricks/frontend'
import 'css/EditorBrickContainer.css'
interface EditorBrickContainerProps {
  children: React.ReactNode
}

export default function EditorBrickContainer({
  children,
}: EditorBrickContainerProps): JSX.Element {
  const { isAdmin } = useAdminContext()
  if (isAdmin) {
    /* 
    .container > div hgroup:first-child > * {
  margin-bottom: 0;
}

    */
    return (
      <div data-theme="light" className="editorBrickContainer">
        {children}
      </div>
    )
  }
  return <>{children}</>
}
