import React from 'react'
import { Text, Image, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import * as style from 'css/FeatureGridItem.module.css'

export interface FeatureGridItemProps {
  title: string
  text: string
  icon?: types.IImageSource
}

const FeatureGridItem: types.Brick<FeatureGridItemProps> = (props) => {
  return (
    <div className={style.item}>
      <Image
        propName="icon"
        alt="Image"
        maxWidth={48}
        imageClassName={style.icon}
      />

      <Text
        propName="title"
        placeholder="Title..."
        renderBlock={({ children }) => (
          <h3 className={style.title}>{children}</h3>
        )}
      />
      <Text
        propName="text"
        placeholder="Text..."
        renderBlock={({ children }) => <p className={style.text}>{children}</p>}
      />
    </div>
  )
}

FeatureGridItem.schema = {
  name: blockNames.FeatureGridItem,
  label: 'FeatureGridItem',
  hideFromAddMenu: true,
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Cash Offer',
    text: 'Get a cash offer on your home in fast!',
    icon: {
      hashId: 'ZrGcI6UvzgO8Q4W',
      src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/-Uoc4YHPRJAGR5k.webp',
      srcSet:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-96.webp 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-72.webp 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-48.webp 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-24.webp 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-12.webp 12w',
      type: 'image/webp',
      placeholderSrc:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/-Uoc4YHPRJAGR5k.jpg',
      fallbackSrc:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/-Uoc4YHPRJAGR5k.png',
      fallbackSrcSet:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-96.png 96w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-72.png 72w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-48.png 48w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-24.png 24w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/-Uoc4YHPRJAGR5k-12.png 12w',
      fallbackType: 'image/png',
      height: 512,
      width: 512,
      alt: '',
      seoName: 'home',
      crop: {
        x: 0,
        y: 0,
        width: 512,
        height: 512,
      },
      transform: {
        rotate: 0,
        flip: {
          horizontal: false,
          vertical: false,
        },
      },
    },
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'icon',
      type: types.SideEditPropType.Image,
      label: 'Image',
    },
  ],
}

export default FeatureGridItem
