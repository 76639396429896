import classNames from 'classnames'
import React from 'react'
import * as styles from 'src/css/Grid.module.css'
interface GridProps {
  children: React.ReactNode
  className?: string
  columns?: number
  gapY?: number
  gapX?: number
}

const gridClasses = {
  1: styles.grid1,
  2: styles.grid2,
  3: styles.grid3,
  4: styles.grid4,
  5: styles.grid5,
  6: styles.grid6,
  7: styles.grid7,
  8: styles.grid8,
  9: styles.grid9,
  10: styles.grid10,
  11: styles.grid11,
  12: styles.grid12,
}

export default function Grid({
  children,
  className,
  columns = 2,
  gapY = 0,
  gapX = 0,
}: GridProps) {
  return (
    <div
      className={classNames(
        className,
        styles.grid,
        gridClasses[columns as keyof typeof gridClasses]
      )}
      style={{
        columnGap: `${gapY}rem`,
        rowGap: `${gapX}rem`,
      }}
    >
      {children}
    </div>
  )
}
