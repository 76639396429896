import React from 'react'
import { ReactBricks } from 'react-bricks/frontend'
import config from './src/react-bricks/config'
import 'css/variables.css'
import './src/sass/pico.scss'

// rendered in production
export const wrapPageElement = ({ element }) => {
  return <ReactBricks {...config}>{element}</ReactBricks>
}
