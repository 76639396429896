import React from 'react'
import {
  Text,
  RichText,
  Image,
  File,
  Repeater,
  types,
} from 'react-bricks/frontend'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import { RichTextProps } from 'src/react-bricks/customTypes'
import classNames from 'classnames'
import blockNames from '../blockNames'
import { accordionItemDefaultProps } from '../defaultProps'

export interface AccordionItemProps {
  title: string
  text: RichTextProps[]
  openOnLoad?: boolean
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'contrast'
    | 'outline'
    | 'outline secondary'
    | 'outline contrast'
}

const AccordionItem: types.Brick<AccordionItemProps> = ({
  openOnLoad = false,
  variant = 'default',
}) => {
  return (
    <details open={openOnLoad}>
      <summary
        role={variant !== 'default' ? 'button' : ''}
        className={classNames(variant)}
      >
        <Text
          propName="title"
          placeholder="Title..."
          renderBlock={({ children }) => <span>{children}</span>}
        />
      </summary>
      <RichText
        propName="text"
        allowedFeatures={[
          types.RichTextFeatures.Bold,
          types.RichTextFeatures.Italic,
          types.RichTextFeatures.Highlight,
          types.RichTextFeatures.Link,
        ]}
      />
    </details>
  )
}

AccordionItem.schema = {
  name: blockNames.AccordionItem,
  label: 'Accordion',
  hideFromAddMenu: true,
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => accordionItemDefaultProps,

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'openOnLoad',
      type: types.SideEditPropType.Boolean,
      label: 'Open on load',
    },
    {
      name: 'variant',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'primary',
            label: 'Primary',
          },
          {
            value: 'secondary',
            label: 'Secondary',
          },
          {
            value: 'contrast',
            label: 'Contrast',
          },
          {
            value: 'outline',
            label: 'Outline',
          },
          {
            value: 'outline secondary',
            label: 'Outline Secondary',
          },
          {
            value: 'outline contrast',
            label: 'Outline Contrast',
          },
        ],
      },
      label: 'Variant',
    },
  ],
}

export default AccordionItem
