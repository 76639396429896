import React from 'react'
import {
  Text,
  RichText,
  Image,
  File,
  Repeater,
  types,
} from 'react-bricks/frontend'
import blockNames from '../blockNames'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import Section, { SectionProps } from '../shared/components/Section'
import { sectionLayoutSideGroup } from '../sideEditProps'
import classNames from 'classnames'
import * as style from 'css/Form.module.css'
import { RichTextProps } from 'src/react-bricks/customTypes'

interface FormSectionProps {
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
  formSubmitButtonText: string
  title: string
  text: RichTextProps[]
}

const FormSection: types.Brick<FormSectionProps> = ({
  paddingTop,
  paddingBottom,
  formSubmitButtonText = 'Submit',
  title,
  text,
}) => {
  return (
    <EditorBrickContainer>
      <Section container paddingTop={paddingTop} paddingBottom={paddingBottom}>
        <article>
          <hgroup>
            <Text
              propName="title"
              placeholder="Title..."
              renderBlock={({ children }) => <h2>{children}</h2>}
            />
            <RichText
              propName="text"
              placeholder="Text..."
              allowedFeatures={[
                types.RichTextFeatures.Bold,
                types.RichTextFeatures.Italic,
                types.RichTextFeatures.Link,
              ]}
            />
          </hgroup>
          <form name="contact" method="post" data-netlify="true">
            <fieldset className={classNames('grid')}>
              <label className={style.fullWidthField}>
                Property Address
                <span className={style.required}>*</span>
                <input
                  name="address"
                  placeholder="Property Address"
                  autoComplete="address"
                  required
                />
              </label>
            </fieldset>
            <fieldset className={classNames('grid')}>
              <label>
                Name <span className={style.required}>*</span>
                <input
                  name="name"
                  placeholder="Your Name"
                  autoComplete="name"
                  required
                />
              </label>
              <label>
                Phone <span className={style.required}>*</span>
                <input
                  id="contact-phone"
                  name="phone"
                  placeholder="Your Phone"
                  autoComplete="tel"
                  pattern="[0-9]{10}"
                  required
                />
              </label>
              <label>
                Email <span className={style.required}>*</span>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  autoComplete="email"
                  required
                />
              </label>
            </fieldset>
            <input type="hidden" name="form-name" value="contact" />
            <button type="submit">{formSubmitButtonText || 'Submit'}</button>
          </form>
        </article>
      </Section>
    </EditorBrickContainer>
  )
}

FormSection.schema = {
  name: blockNames.FormSection,
  label: 'Form',
  previewImageUrl: `/bricks/${blockNames.FormSection}.png`,
  category: 'Form',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    paddingTop: 'large',
    paddingBottom: 'large',
    formSubmitButtonText: 'Submit',
    title: 'Contact Us',
    text: [
      { type: 'p', children: [{ text: 'Contact us for more information.' }] },
    ],
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    sectionLayoutSideGroup,
    {
      name: 'formSubmitButtonText',
      label: 'Submit Button Text',
      type: types.SideEditPropType.Text,
    },
  ],
}

export default FormSection
