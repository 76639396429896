import React, { useState } from 'react'
import { Repeater, types, Link } from 'react-bricks/frontend'
import { FiMenu, FiX } from 'react-icons/fi'
import blockNames from '../blockNames'
import { ButtonProps } from '../shared/bricks/Button'
import { MenuItems } from './HeaderMenuItem'
import * as style from 'css/Header.module.css'
import classNames from 'classnames'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'

interface HeaderProps {
  menuItems: MenuItems[]
  logo: types.IImageSource
  buttons: ButtonProps[]
}

const Header: types.Brick<HeaderProps> = ({}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <EditorBrickContainer>
      <nav className="container-fluid">
        <ul>
          <li>
            <Link href="/" className="contrast">
              <strong>SellingHouseBrokers.com</strong>
            </Link>
          </li>
        </ul>
        <ul>
          <Repeater
            propName="menuItems"
            renderItemWrapper={(item) => (
              <li key={item.key} className={style.headerMenuItem}>
                {item}
              </li>
            )}
          />
          <Repeater
            propName="buttons"
            itemProps={{ useAsButton: true }}
            renderItemWrapper={(item) => (
              <li key={item.key} className={style.headerMenuItem}>
                {item}
              </li>
            )}
          />
          <MobileMenuButton
            setMobileMenuOpen={setMobileMenuOpen}
            mobileMenuOpen={mobileMenuOpen}
          />
        </ul>
      </nav>
    </EditorBrickContainer>
  )
}

interface MobileMenuButtonProps {
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  mobileMenuOpen: boolean
}

const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({
  setMobileMenuOpen,
  mobileMenuOpen,
}) => {
  return (
    <li className={classNames(style.mobileMenuContainer, 'secondary outline')}>
      <details className="dropdown">
        <summary
          className={style.mobileMenuButton}
          onClick={() => setMobileMenuOpen((prev) => !prev)}
        >
          {mobileMenuOpen ? <FiX /> : <FiMenu />}
        </summary>
        <ul dir="rtl" className={style.mobileMenu}>
          <Repeater
            propName="menuItems"
            renderItemWrapper={(item) => <li key={item.key}>{item}</li>}
            itemProps={{ mobileOnly: true }}
          />
        </ul>
      </details>
    </li>
  )
}

Header.schema = {
  name: blockNames.Header,
  label: 'Header',
  category: 'layout',
  tags: ['header', 'menu'],
  previewImageUrl: `/bricks/${blockNames.Header}.png`,
  repeaterItems: [
    {
      name: 'menuItems',
      itemType: blockNames.HeaderMenuItem,
      itemLabel: 'Item',
      min: 0,
      max: 6,
    },
    {
      name: 'buttons',
      itemType: blockNames.Button,
      itemLabel: 'Button',
      min: 0,
      max: 2,
    },
  ],
  sideEditProps: [],
  getDefaultProps: () => ({
    borderBottom: 'none',
    menuItems: [
      {
        linkPath: '/',
        linkText: 'Home',
      },
      {
        linkPath: '/about-us',
        linkText: 'About us',
      },
      {
        linkPath: '',
        linkText: 'Features',
        submenuItems: [
          {
            linkText: 'Visual editing',
            linkDescription:
              'The best visual experience for your content editors',
            linkPath: '/',
          },
        ],
      },
    ],
    logo: {
      src: 'https://images.reactbricks.com/original/a63a73db-8df4-4a01-9084-44b8217332cf.svg',
      placeholderSrc:
        'https://images.reactbricks.com/original/a63a73db-8df4-4a01-9084-44b8217332cf.svg',
      srcSet: '',
      width: 450,
      height: 100,
      alt: 'React Bricks',
      seoName: 'react-bricks',
    },
    buttons: [],
  }),
}

export default Header
