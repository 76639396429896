import React from 'react'
import { Repeater, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import Section, { SectionProps } from '../shared/components/Section'
import * as style from 'css/NavigationCards.module.css'
import classNames from 'classnames'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import { NavigationCardProps } from './NavigationCard'
import { sectionLayoutSideGroup } from '../sideEditProps'
import { NavigationCardsDefaultProps } from '../defaultProps'

export interface NavigationCardsProps {
  items: NavigationCardProps[]
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
  backgroundColor: SectionProps['backgroundColor']
}

const NavigationCards: types.Brick<NavigationCardsProps> = ({
  paddingBottom,
  paddingTop,
  backgroundColor,
}) => {
  return (
    <EditorBrickContainer>
      <Section
        container
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        backgroundColor={backgroundColor}
      >
        <Repeater
          propName="items"
          renderWrapper={(items) => (
            <div className={classNames('grid', style.cardsContainer)}>
              {items}
            </div>
          )}
        />
      </Section>
    </EditorBrickContainer>
  )
}

NavigationCards.schema = {
  name: blockNames.NavigationCards,
  label: 'Navigation Cards',
  previewImageUrl: `/bricks/${blockNames.NavigationCards}.png`,
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => NavigationCardsDefaultProps,

  repeaterItems: [
    {
      name: 'items',
      itemType: blockNames.NavigationCard,
      itemLabel: 'Navigation Card',
      min: 1,
      max: 4,
    },
  ],
  // Sidebar Edit controls for props
  sideEditProps: [sectionLayoutSideGroup],
}

export default NavigationCards
