import React from 'react'
import {
  Text,
  RichText,
  Image,
  File,
  Repeater,
  types,
} from 'react-bricks/frontend'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import { accordionGroupDefaultProps } from '../defaultProps'
import Section, { SectionProps } from '../shared/components/Section'
import { sectionLayoutSideGroup } from '../sideEditProps'
import blockNames from '../blockNames'
import { AccordionItemProps } from './AccordionItem'
import * as style from 'css/AccordionGroup.module.css'

export interface AccordionsProps {
  accordions: AccordionItemProps[]
  paddingTop?: SectionProps['paddingTop']
  paddingBottom?: SectionProps['paddingBottom']
  backgroundColor?: SectionProps['backgroundColor']
}

const AccordionGroup: types.Brick<AccordionsProps> = ({
  paddingTop,
  paddingBottom,
  backgroundColor
}) => {
  return (
    <EditorBrickContainer>
      <Section container paddingTop={paddingTop} paddingBottom={paddingBottom} backgroundColor={backgroundColor}>
        <Repeater
          propName="accordions"
          renderItemWrapper={(item) => (
            <div>
              {item}
              <hr />
            </div>
          )}
        />
      </Section>
    </EditorBrickContainer>
  )
}

AccordionGroup.schema = {
  name: blockNames.AccordionGroup,
  label: 'Accordion Group',
  previewImageUrl: `/bricks/${blockNames.AccordionGroup}.png`,
  getDefaultProps: () => accordionGroupDefaultProps,
  repeaterItems: [
    {
      name: 'accordions',
      itemType: blockNames.AccordionItem,
      itemLabel: 'Accordion',
      min: 1,
    },
  ],
  sideEditProps: [sectionLayoutSideGroup],
}

export default AccordionGroup
