import { types } from 'react-bricks/frontend'
import {
  // highlightTextColors,
  bgColors,
  // highlightBgColors,
  // buttonColors,
  // gradients,
  // pricingColors,
} from './colors'

export const backgroundColorsEditProps: types.ISideEditProp = {
  name: 'backgroundColor',
  label: 'Background',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: [
      bgColors.WHITE,
      bgColors.RED.LIGHT,
      bgColors.PINK.LIGHT,
      bgColors.FUCHSIA.LIGHT,
      bgColors.PURPLE.LIGHT,
      bgColors.VIOLET.LIGHT,
      bgColors.INDIGO.LIGHT,
      bgColors.BLUE.LIGHT,
      bgColors.AZURE.LIGHT,
      bgColors.CYAN.LIGHT,
      bgColors.JADE.LIGHT,
      bgColors.GREEN.LIGHT,
      bgColors.LIME.LIGHT,
      bgColors.YELLOW.LIGHT,
      bgColors.AMBER.LIGHT,
      bgColors.PUMPKIN.LIGHT,
      bgColors.ORANGE.LIGHT,
      bgColors.SAND.LIGHT,
      bgColors.GREY.LIGHT,
      bgColors.ZINC.LIGHT,
      bgColors.SLATE.LIGHT,
    ],
  },
}

export type BackgroundColorType = {
  color: string
  className: string
}

export const sectionLayoutSideGroup: types.ISideGroup = {
  groupName: 'Layout',
  props: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { value: 'none', label: 'None' },
          { value: 'small', label: 'Small' },
          { value: 'medium', label: 'Medium' },
          { value: 'large', label: 'Large' },
          { value: 'xlarge', label: 'X-Large' },
        ],
      },
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { value: 'none', label: 'None' },
          { value: 'small', label: 'Small' },
          { value: 'medium', label: 'Medium' },
          { value: 'large', label: 'Large' },
          { value: 'xlarge', label: 'X-Large' },
        ],
      },
    },
    backgroundColorsEditProps,
  ],
}

// export const neutralBackgroundColorsEditProps: types.ISideEditProp = {
//   name: 'backgroundColor',
//   label: 'Background',
//   type: types.SideEditPropType.Select,
//   selectOptions: {
//     display: types.OptionsDisplay.Color,
//     options: [bgColors.WHITE, bgColors.LIGHT_GRAY, bgColors.GRAY, bgColors.DARK_GRAY],
//   },
// }

export const overlaySideEditProps: types.ISideEditProp[] = [
  {
    name: 'overlay',
    label: 'Overlay',
    type: types.SideEditPropType.Select,
    selectOptions: {
      display: types.OptionsDisplay.Select,
      options: [
        { value: 'none', label: 'None' },
        { value: 'dark', label: 'Dark' },
        { value: 'light', label: 'Light' },
      ],
    },
  },
]

export const sectionHeadingSideProps: types.ISideEditProp[] = [
  {
    name: 'title',
    label: 'Title',
    type: types.SideEditPropType.Text,
  },
  {
    name: 'subtitle',
    label: 'Subtitle',
    type: types.SideEditPropType.Text,
  },
]
