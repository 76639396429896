import React from 'react'
import { Repeater, RichText, types } from 'react-bricks/frontend'
import * as style from 'css/Footer.module.css'
import Flex from '../shared/components/Flex'
import Grid from '../shared/components/Grid'
import Container from '../shared/components/Container'
import blockNames from '../blockNames'
import { FooterColumnProps } from './FooterColumn'

interface FooterProps {
  columns: FooterColumnProps[]
}

const Footer: types.Brick<FooterProps> = () => {
  return (
    <footer data-theme="dark" className={style.footer}>
      <Container>
        <Grid className={style.footerContainer}>
          <Flex flexFlow="column" justifyContent="space-between">
            <RichText propName="text"
              allowedFeatures={[
                types.RichTextFeatures.Bold,
                types.RichTextFeatures.Italic,
                types.RichTextFeatures.Link,
                types.RichTextFeatures.OrderedList,
                types.RichTextFeatures.UnorderedList,
                types.RichTextFeatures.Heading3,
                types.RichTextFeatures.Heading4,
              ]}
            />
            <p className={style.copy}>
              &copy; {new Date().getFullYear()} SellingHouseBrokers.com
            </p>
          </Flex>
          <Grid className={style.linkCols}>
            <Repeater propName="columns" />
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer

Footer.schema = {
  name: blockNames.Footer,
  label: 'Footer',
  category: 'layout',
  tags: [],
  previewImageUrl: `/bricks/${blockNames.Footer}.png`,
  getDefaultProps: () => ({
    columns: [
      {
        title: 'Column 1',
        items: [
          {
            title: 'Link 1',
            href: '/',
          },
          {
            title: 'Link 2',
            href: '/',
          },
        ],
      },
      {
        title: 'Column 2',
        items: [
          {
            title: 'Link 1',
            href: '/',
          },
          {
            title: 'Link 2',
            href: '/',
          },
        ],
      },
      {
        title: 'Column 3',
        items: [
          {
            title: 'Link 1',
            href: '/',
          },
          {
            title: 'Link 2',
            href: '/',
          },
        ],
      },
      {
        title: 'Column 4',
        items: [
          {
            title: 'Link 1',
            href: '/',
          },
          {
            title: 'Link 2',
            href: '/',
          },
        ],
      },
    ],
  }),
  repeaterItems: [
    {
      name: 'columns',
      itemType: blockNames.FooterColumn,
      itemLabel: 'Column',
    },
  ],
  sideEditProps: [],
}
