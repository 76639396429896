import { NavigationCardsProps } from './NavigationCards/NavigationCards'
import { NavigationCardProps } from './NavigationCards/NavigationCard'
import { ButtonProps } from './shared/bricks/Button'
import { TextFormProps } from './TextForm'
import { CtaBannerProps } from './Cta/CtaBanner'
import { TeamMemberContentProps } from './team-member/Content'
import { HorizontalHeroProps } from './Hero/HorizontalHero'
import { ParagraphProps } from './Paragraph'
import { AccordionItemProps } from './Accordions/AccordionItem'
import { AccordionsProps } from './Accordions/AccordionGroup'

export const NavigationCardsDefaultProps: NavigationCardsProps = {
  paddingBottom: 'medium',
  paddingTop: 'medium',
  items: [
    {
      title: 'Lorem Ipsum',
      text: [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit feugiat suscipit torquent ',
            },
            {
              text: 'tortor vivamus',
              bold: true,
            },
            {
              text: ' sollicitudin ante hac blandit, magnis in donec etiam hendrerit vitae convallis phasellus sociis aliquam penatibus massa est laoreet.',
            },
          ],
        },
      ],
      buttons: [
        {
          linkType: 'external-link',
          href: 'https://www.example.com',
          variant: 'primary',
          openInNewTab: false,
          text: 'Lorem Ipsum',
          umamiEnabled: false,
        },
      ],
      image: {
        hashId: 'tpBS1vp3Ovob-0S',
        src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/_QbI6wGYSTfO3IQ.webp',
        srcSet:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-1000.webp 1000w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-800.webp 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-400.webp 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-200.webp 200w',
        type: 'image/webp',
        placeholderSrc:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/_QbI6wGYSTfO3IQ.jpg',
        fallbackSrc:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/_QbI6wGYSTfO3IQ.jpg',
        fallbackSrcSet:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-1000.jpg 1000w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-800.jpg 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-400.jpg 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-200.jpg 200w',
        fallbackType: 'image/jpeg',
        height: 667,
        width: 1000,
        alt: '',
        seoName: 'rundown-home',
        crop: {
          x: 0,
          y: 0,
          width: 1000,
          height: 667,
        },
        transform: {
          rotate: 0,
          flip: {
            horizontal: false,
            vertical: false,
          },
        },
      },
      overlay: 'dark',
    },
    {
      title: 'Lorem Ipsum',
      text: [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit feugiat suscipit torquent tortor vivamus.',
            },
          ],
        },
        {
          type: 'paragraph',
          children: [
            {
              text: 'Diam euismod nunc quis nisl montes facilisis ante mauris, integer non dui tortor arcu maecenas ',
            },
            {
              text: 'scelerisque id',
              italic: true,
            },
            {
              text: ' aliquam, praesent erat semper quam lacus mus accumsan.',
            },
          ],
        },
      ],
      buttons: [
        {
          linkType: 'external-link',
          href: 'https://www.example.com',
          variant: 'primary',
          openInNewTab: false,
          text: 'Lorem Ipsum',
          umamiEnabled: false,
        },
      ],
      image: {
        hashId: 'BbswT0csrfb30W1',
        src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/nGakBzJ6S3mlHyj.webp',
        srcSet:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-1080.webp 1080w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-800.webp 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-400.webp 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-200.webp 200w',
        type: 'image/webp',
        placeholderSrc:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/nGakBzJ6S3mlHyj.jpg',
        fallbackSrc:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/nGakBzJ6S3mlHyj.jpg',
        fallbackSrcSet:
          'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-1080.jpg 1080w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-800.jpg 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-400.jpg 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/nGakBzJ6S3mlHyj-200.jpg 200w',
        fallbackType: 'image/jpeg',
        height: 912,
        width: 1080,
        alt: 'a house made out of money on a white background',
        seoName: 'a-house-made-out-of-money-on-a-white-background-1scxwvoqkaw',
        crop: {
          x: 0,
          y: 0,
          width: 1080,
          height: 912,
        },
        transform: {
          rotate: 0,
          flip: {
            horizontal: false,
            vertical: false,
          },
        },
      },
      overlay: 'light',
    },
  ],
}

export const NavigationCardDefaultProps: NavigationCardProps = {
  title: 'Lorem Ipsum',
  overlay: 'dark',
  text: [
    {
      type: 'paragraph',
      children: [
        {
          text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit feugiat suscipit torquent ',
        },
        {
          text: 'tortor vivamus',
          bold: true,
        },
        {
          text: ' sollicitudin ante hac blandit, magnis in donec etiam hendrerit vitae convallis phasellus sociis aliquam penatibus massa est laoreet.',
        },
      ],
    },
  ],
  buttons: [
    {
      linkType: 'external-link',
      href: 'https://www.example.com',
      variant: 'primary',
      openInNewTab: false,
      text: 'Lorem Ipsum',
      umamiEnabled: false,
    },
  ],
  image: {
    hashId: 'tpBS1vp3Ovob-0S',
    src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/_QbI6wGYSTfO3IQ.webp',
    srcSet:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-1000.webp 1000w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-800.webp 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-400.webp 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/_QbI6wGYSTfO3IQ-200.webp 200w',
    type: 'image/webp',
    placeholderSrc:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/_QbI6wGYSTfO3IQ.jpg',
    fallbackSrc:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/_QbI6wGYSTfO3IQ.jpg',
  },
}

export const ButtonDefaultProps: ButtonProps = {
  linkType: 'external-link',
  href: 'https://www.example.com',
  variant: 'primary',
  openInNewTab: false,
  text: 'Lorem Ipsum',
  umamiEnabled: false,
}

export const TextFormDefaultProps: TextFormProps = {
  title: 'Sell Your Home To Us!',
  subtitle: 'Get started on these benefits by filling out the form!',
  text: [
    {
      type: 'ul',
      children: [
        {
          type: 'li',
          children: [
            {
              text: 'Quick Sale',
              bold: true,
            },
            {
              text: ': We buy houses fast!',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'No Fees or Commissions',
              bold: true,
            },
            {
              text: ': No real estate agent fees or commissions.',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'As-Is Sale',
              bold: true,
            },
            {
              text: ': We buy homes in any condition, no need for repairs.',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'Flexible Closing',
              bold: true,
            },
            {
              text: ': We work with your timeline.',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'Avoid Foreclosure',
              bold: true,
            },
            {
              text: ': Sell your house quickly to avoid foreclosure.',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'No Showings or Open Houses',
              bold: true,
            },
            {
              text: ': Skip the hassle.',
            },
          ],
        },
      ],
    },
  ],
  formPosition: 'right',
  paddingTop: 'large',
  paddingBottom: 'large',
  backgroundColor: {
    color: '#fff',
    className: null,
  },
}

export const CtaBannerDefaultProps: CtaBannerProps = {
  title: 'Lorem Ipsum',
  text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, aliquet curae velit sapien lobortis odio, metus tempor dis risus accumsan euismod.',
  subtitle: 'Lorem ipsum dolor sit amet consectetur.',
  paddingTop: 'medium',
  paddingBottom: 'medium',
  buttonText: 'Get A Cash Offer Now',
  backgroundImage: {
    hashId: '6EgNgJeU8LTNs_6',
    src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/JJUNO4fCWr_bNj-.webp',
    srcSet:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--1500.webp 1500w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--1200.webp 1200w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--800.webp 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--400.webp 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--200.webp 200w',
    type: 'image/webp',
    placeholderSrc:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/JJUNO4fCWr_bNj-.jpg',
    fallbackSrc:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/JJUNO4fCWr_bNj-.jpg',
    fallbackSrcSet:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--1500.jpg 1500w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--1200.jpg 1200w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--800.jpg 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--400.jpg 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/JJUNO4fCWr_bNj--200.jpg 200w',
    fallbackType: 'image/jpeg',
    height: 599,
    width: 1500,
    alt: '',
    seoName: '22228082',
    crop: {
      x: 0,
      y: 0,
      width: 1500,
      height: 599,
    },
    transform: {
      rotate: 0,
      flip: {
        horizontal: false,
        vertical: false,
      },
    },
  },
}

export const TeamMemberContentDefaultProps: TeamMemberContentProps = {
  text: [
    {
      type: 'paragraph',
      children: [
        {
          text: 'Lorem ipsum inceptos habitasse erat tempus metus aliquet habitant himenaeos praesent egestas maecenas, mattis potenti vulputate fringilla primis faucibus fames scelerisque turpis conubia commodo, curae vestibulum nostra etiam euismod eget integer orci in laoreet. Mollis varius morbi diam per duis luctus magnis fusce, eleifend lobortis ut aenean ultricies bibendum quis, risus ligula rhoncus nec maecenas iaculis parturient. Magnis conubia elementum ultricies felis facilisi sapien ultrices primis tincidunt, a nostra tempor non leo ante porttitor ac mauris, taciti pellentesque egestas nam duis erat urna hac.',
        },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: 'Blandit habitant suspendisse maecenas litora per ad sed tempor, augue malesuada scelerisque duis feugiat consequat parturient, at integer aliquam tempus gravida cubilia fusce. Curae pulvinar nisi porttitor vel convallis odio dictumst magna, urna auctor in rhoncus dui ullamcorper sodales, luctus elementum facilisi erat nisl torquent sollicitudin.',
        },
      ],
    },
  ],
  role: 'Business Role',
  title: 'John Doe',
}

export const horizontalHeroDefaultProps: HorizontalHeroProps = {
  paddingTop: 'medium',
  paddingBottom: 'medium',
  title: [
    {
      type: 'paragraph',
      children: [
        {
          text: 'Want To ',
        },
        {
          text: 'Sell',
          highlight: true,
        },
        {
          text: '\nYour House ',
        },
        {
          text: 'Fast',
          highlight: true,
        },
        {
          text: '\nIn Florida?',
        },
      ],
    },
  ],
  text: [
    {
      type: 'paragraph',
      children: [
        {
          text: 'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        },
      ],
    },
    {
      type: 'ul',
      children: [
        {
          type: 'li',
          children: [
            {
              text: 'Lorem ipsum dolor',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'Fit amet consectetur',
            },
          ],
        },
        {
          type: 'li',
          children: [
            {
              text: 'Adipiscing elit sed do eiusmod',
            },
          ],
        },
      ],
    },
  ],
  backgroundImage: {
    hashId: '9wSphhOEXhq9ZZH',
    src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/PlVcnKPAwMycf4i.webp',
    srcSet:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-1080.webp 1080w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-800.webp 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-400.webp 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-200.webp 200w',
    type: 'image/webp',
    placeholderSrc:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/PlVcnKPAwMycf4i.jpg',
    fallbackSrc:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/PlVcnKPAwMycf4i.jpg',
    fallbackSrcSet:
      'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-1080.jpg 1080w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-800.jpg 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-400.jpg 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/PlVcnKPAwMycf4i-200.jpg 200w',
    fallbackType: 'image/jpeg',
    height: 767,
    width: 1080,
    alt: 'white-and-red houses',
    seoName: 'white-and-red-houses-f9qzukozyoy',
    crop: {
      x: 0,
      y: 0,
      width: 1080,
      height: 767,
    },
    transform: {
      rotate: 0,
      flip: {
        horizontal: false,
        vertical: false,
      },
    },
  },
  overlay: 'dark',
  buttons: [
    {
      linkType: 'external-link',
      href: 'https://www.example.com',
      variant: 'primary',
      openInNewTab: false,
      text: 'Feature',
      umamiEnabled: false,
    },
    {
      linkType: 'external-link',
      href: 'https://www.example.com',
      variant: 'link',
      openInNewTab: false,
      text: 'Contact Us',
      umamiEnabled: false,
    },
  ],
}

export const paragraphDefaultProps: ParagraphProps = {
  text: [
    {
      type: 'h1',
      children: [
        {
          text: 'Lorem ipsum',
        },
      ],
    },
    {
      type: 'h2',
      children: [
        {
          text: 'dolor sit amet consectetur',
        },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: 'Adipiscing elit, augue sollicitudin sociis suspendisse morbi malesuada metus aliquam, magna laoreet potenti faucibus hac et. Sem penatibus class fermentum potenti ',
        },
        {
          type: 'link',
          data: {
            url: '/',
            isTargetBlank: false,
          },
          children: [
            {
              text: 'rutrum malesuada',
            },
          ],
        },
        {
          text: ' nullam mus sagittis ridiculus, netus egestas vehicula ',
        },
        {
          text: 'bibendum felis ',
          bold: true,
        },
        {
          text: 'sollicitudin inceptos scelerisque nam enim nunc, phasellus facilisi dui primis odio nascetur fusce gravida ',
        },
        {
          text: 'curabitur',
          italic: true,
        },
        {
          text: '.',
        },
      ],
    },
    {
      type: 'quote',
      children: [
        {
          text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, ante natoque pharetra nullam in condimentum, gravida facilisis mattis pulvinar varius litora.',
        },
      ],
    },
  ],
  paddingTop: 'large',
  paddingBottom: 'large',
}

export const accordionItemDefaultProps: AccordionItemProps = {
  title: 'Lorem Ipsum',
  text: [
    {
      type: 'p',
      children: [
        {
          text: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
        },
      ],
    },
  ],
  variant: 'default',
  openOnLoad: true,
}

export const accordionGroupDefaultProps: AccordionsProps = {
  accordions: [
    accordionItemDefaultProps,
    accordionItemDefaultProps,
    accordionItemDefaultProps,
  ],
  paddingTop: 'large',
  paddingBottom: 'large',
}
