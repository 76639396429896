import React from 'react'
import { Repeater, Text, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import { FooterLinkProps } from './FooterLink'

export interface FooterColumnProps {
  title: string
  items: FooterLinkProps[]
}

const FooterCol: types.Brick<FooterColumnProps> = ({}) => {
  return (
    <aside>
      <nav>
        <ul>
          <li>
            <strong>
              <Text
                propName="title"
                renderBlock={({ children }) => (
                  <span className="col-heading">{children}</span>
                )}
              />
            </strong>
          </li>
          <Repeater
            propName="items"
            renderItemWrapper={(item) => <li key={item.key}>{item}</li>}
          />
        </ul>
      </nav>
    </aside>
  )
}

export default FooterCol

FooterCol.schema = {
  name: blockNames.FooterColumn,
  label: 'Footer Column',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    title: 'Column Title',
    items: [
      {
        title: 'Link 1',
        href: '/',
      },
      {
        title: 'Link 2',
        href: '/',
      },
      {
        title: 'Link 3',
        href: '/',
      },
      {
        title: 'Link 4',
        href: '/',
      },
    ],
  }),
  repeaterItems: [
    {
      name: 'items',
      itemType: blockNames.FooterLink,
      itemLabel: 'Link',
    },
  ],
  sideEditProps: [],
}
