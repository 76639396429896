import React from 'react'
import { Text, types } from 'react-bricks/frontend'

import * as style from 'css/Heading.module.css'
import blockNames from '../blockNames'
import classNames from 'classnames'
import Section from '../shared/components/Section'
import { SectionProps } from '../shared/components/Section'
import { sectionLayoutSideGroup } from '../sideEditProps'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'

interface HeadingProps {
  position: 'left' | 'center' | 'right'
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
  backgroundColor: SectionProps['backgroundColor']
}

const index: types.Brick<HeadingProps> = ({
  position,
  paddingBottom,
  paddingTop,
  backgroundColor
}) => {
  return (
    <EditorBrickContainer>
      <Section container paddingBottom={paddingBottom} paddingTop={paddingTop} backgroundColor={backgroundColor}>
        <hgroup
          className={classNames(
            style.headingGroup,
            position === 'center' && style.center,
            position === 'right' && style.right
          )}
        >
          <h2>
            <Text
              propName="title"
              placeholder="Title..."
              renderBlock={({ children }) => <span>{children}</span>}
            />
          </h2>
          <Text propName="subtitle" placeholder="Subtitle..." />
        </hgroup>
      </Section>
    </EditorBrickContainer>
  )
}

index.schema = {
  name: blockNames.Heading,
  label: 'Heading',
  previewImageUrl: `/bricks/${blockNames.Heading}.png`,
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    paddingTop: 'medium',
    paddingBottom: 'medium',
    title: 'Housing Sells',
    subtitle: 'Find your dream home today!',
    position: 'left',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    sectionLayoutSideGroup,
    {
      name: 'position',
      label: 'Position',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Right' },
        ],
      },
    },
  ],
}

export default index
