import React from 'react'
import { Image, Repeater, RichText, Text, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import Section, { SectionProps } from '../shared/components/Section'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import { sectionLayoutSideGroup } from '../sideEditProps'
import * as style from 'css/CtaBanner.module.css'
import Container from '../shared/components/Container'
import Button from '../shared/bricks/Button'
import { CtaBannerDefaultProps } from '../defaultProps'

export interface CtaBannerProps {
  title?: string
  subtitle?: string
  text?: any
  backgroundColor?: string
  textTheme?: 'light' | 'dark'
  paddingTop?: SectionProps['paddingTop']
  paddingBottom?: SectionProps['paddingBottom']
  backgroundImage?: types.IImageSource
  url?: string
  buttonText?: string
}

const CtaBanner: types.Brick<CtaBannerProps> = ({
  backgroundColor,
  textTheme,
  paddingTop,
  paddingBottom,
  backgroundImage,
  url,
}) => {
  return (
    <EditorBrickContainer>
      <Section
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        className={style.section}
      >
        {backgroundImage ? (
          <Image
            propName="backgroundImage"
            alt={backgroundImage?.alt}
            imageClassName={style.backgroundImage}
          />
        ) : null}
        <Container className={style.container} theme="dark">
          <div>
            <hgroup>
              <Text
                propName="title"
                placeholder="Title..."
                renderBlock={({ children }) => <h2>{children}</h2>}
              />
              <Text
                propName="subtitle"
                placeholder="Subtitle..."
                renderBlock={({ children }) => (
                  <p className="pico-color-slate-300">{children}</p>
                )}
              />
            </hgroup>

            <RichText
              propName="text"
              placeholder="Text..."
              allowedFeatures={[
                types.RichTextFeatures.Bold,
                types.RichTextFeatures.Italic,
                types.RichTextFeatures.Highlight,
                types.RichTextFeatures.Link,
              ]}
            />
          </div>
          <div className={style.ctaContainer}>
            <Repeater
              propName="button"
              itemProps={{
                className: style.cta,
              }}
            />
          </div>
        </Container>
      </Section>
    </EditorBrickContainer>
  )
}

CtaBanner.schema = {
  name: blockNames.CtaBanner,
  label: 'CtaBanner',
  previewImageUrl: `/bricks/${blockNames.CtaBanner}.png`,
  category: 'CTA',
  tags: ['CTA', 'Banner'],
  getDefaultProps: () => CtaBannerDefaultProps,
  repeaterItems: [
    {
      name: 'button',
      itemLabel: 'Button',
      itemType: blockNames.Button,
      min: 1,
      max: 1,
    },
  ],
  sideEditProps: [
    sectionLayoutSideGroup,
    {
      name: 'backgroundImage',
      label: 'Background image',
      type: types.SideEditPropType.Image,
    },
  ],
}

export default CtaBanner
