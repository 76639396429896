// ====================
// PICOCSS COLORS
// ====================

type PicoColor = {
  name: string
  label: string
  color500: string
  LIGHT?: PicoColor
  DARK?: PicoColor
}
type PicoColors = Record<string, PicoColor>

const picoColors = {
  RED: {
    name: 'red',
    label: 'Red',
    color500: '#d93526',
    LIGHT: { name: 'red-light', label: 'Red Light', color500: '#faeeeb' },
    DARK: { name: 'red-dark', label: 'Red Dark', color500: '#c52f21' },
  },
  PINK: {
    name: 'pink',
    label: 'Pink',
    color500: '#d92662',
    LIGHT: { name: 'pink-light', label: 'Pink Light', color500: '#fbedef' },
    DARK: { name: 'pink-dark', label: 'Pink Dark', color500: '#c72259' },
  },
  FUCHSIA: {
    name: 'fuchsia',
    label: 'Fuchsia',
    color500: '#d9269d',
    LIGHT: {
      name: 'fuchsia-light',
      label: 'Fuchsia Light',
      color500: '#fbedf7',
    },
    DARK: { name: 'fuchsia-dark', label: 'Fuchsia Dark', color500: '#c7228b' },
  },
  PURPLE: {
    name: 'purple',
    label: 'Purple',
    color500: '#b645cd',
    LIGHT: { name: 'purple-light', label: 'Purple Light', color500: '#f2e9f7' },
    DARK: { name: 'purple-dark', label: 'Purple Dark', color500: '#a63db9' },
  },
  VIOLET: {
    name: 'violet',
    label: 'Violet',
    color500: '#9062ca',
    LIGHT: { name: 'violet-light', label: 'Violet Light', color500: '#edeaf7' },
    DARK: { name: 'violet-dark', label: 'Violet Dark', color500: '#7e57c2' },
  },
  INDIGO: {
    name: 'indigo',
    label: 'Indigo',
    color500: '#7569da',
    LIGHT: { name: 'indigo-light', label: 'Indigo Light', color500: '#eaeaf7' },
    DARK: { name: 'indigo-dark', label: 'Indigo Dark', color500: '#5e55b7' },
  },
  BLUE: {
    name: 'blue',
    label: 'Blue',
    color500: '#3c71f7',
    LIGHT: { name: 'blue-light', label: 'Blue Light', color500: '#e7eefb' },
    DARK: { name: 'blue-dark', label: 'Blue Dark', color500: '#2a5fd7' },
  },
  AZURE: {
    name: 'azure',
    label: 'Azure',
    color500: '#017fc0',
    LIGHT: { name: 'azure-light', label: 'Azure Light', color500: '#e4f2f7' },
    DARK: { name: 'azure-dark', label: 'Azure Dark', color500: '#016a9a' },
  },
  CYAN: {
    name: 'cyan',
    label: 'Cyan',
    color500: '#058686',
    LIGHT: { name: 'cyan-light', label: 'Cyan Light', color500: '#e4f7f7' },
    DARK: { name: 'cyan-dark', label: 'Cyan Dark', color500: '#046565' },
  },
  JADE: {
    name: 'jade',
    label: 'Jade',
    color500: '#00895a',
    LIGHT: { name: 'jade-light', label: 'Jade Light', color500: '#e2f7ed' },
    DARK: { name: 'jade-dark', label: 'Jade Dark', color500: '#00724b' },
  },
  GREEN: {
    name: 'green',
    label: 'Green',
    color500: '#398712',
    LIGHT: { name: 'green-light', label: 'Green Light', color500: '#eaf7e2' },
    DARK: { name: 'green-dark', label: 'Green Dark', color500: '#2d6e0a' },
  },
  LIME: {
    name: 'lime',
    label: 'Lime',
    color500: '#628100',
    LIGHT: { name: 'lime-light', label: 'Lime Light', color500: '#f2f7e9' },
    DARK: { name: 'lime-dark', label: 'Lime Dark', color500: '#4f6100' },
  },
  YELLOW: {
    name: 'yellow',
    label: 'Yellow',
    color500: '#827800',
    LIGHT: { name: 'yellow-light', label: 'Yellow Light', color500: '#f7f5e4' },
    DARK: { name: 'yellow-dark', label: 'Yellow Dark', color500: '#665400' },
  },
  AMBER: {
    name: 'amber',
    label: 'Amber',
    color500: '#977000',
    LIGHT: { name: 'amber-light', label: 'Amber Light', color500: '#f7f2e4' },
    DARK: { name: 'amber-dark', label: 'Amber Dark', color500: '#7a5a00' },
  },
  PUMPKIN: {
    name: 'pumpkin',
    label: 'Pumpkin',
    color500: '#ad6400',
    LIGHT: {
      name: 'pumpkin-light',
      label: 'Pumpkin Light',
      color500: '#fceee3',
    },
    DARK: { name: 'pumpkin-dark', label: 'Pumpkin Dark', color500: '#8b4f00' },
  },
  SAND: {
    name: 'sand',
    label: 'Sand',
    color500: '#7b776b',
    LIGHT: { name: 'sand-light', label: 'Sand Light', color500: '#f2f0ec' },
    DARK: { name: 'sand-dark', label: 'Sand Dark', color500: '#55524a' },
  },
  ORANGE: {
    name: 'orange',
    label: 'Orange',
    color500: '#d24317',
    LIGHT: { name: 'orange-light', label: 'Orange Light', color500: '#fbebe4' },
    DARK: { name: 'orange-dark', label: 'Orange Dark', color500: '#b5360f' },
  },
  GREY: {
    name: 'grey',
    label: 'Grey',
    color500: '#777777',
    LIGHT: { name: 'grey-light', label: 'Grey Light', color500: '#f2f2f2' },
    DARK: { name: 'grey-dark', label: 'Grey Dark', color500: '#5e5e5e' },
  },
  ZINC: {
    name: 'zinc',
    label: 'Zinc',
    color500: '#6f7887',
    LIGHT: { name: 'zinc-light', label: 'Zinc Light', color500: '#f2f4f6' },
    DARK: { name: 'zinc-dark', label: 'Zinc Dark', color500: '#5e6775' },
  },
  SLATE: {
    name: 'slate',
    label: 'Slate',
    color500: '#687899',
    LIGHT: { name: 'slate-light', label: 'Slate Light', color500: '#f2f4f7' },
    DARK: { name: 'slate-dark', label: 'Slate Dark', color500: '#4f5a7a' },
  },
} as const satisfies PicoColors

// ====================
// TEXT COLORS
// ====================

export const textColors = {
  GRAY_900: 'text-grey-900 dark:text-white',
  GRAY_800: 'text-grey-800 dark:text-white',
  GRAY_700: 'text-grey-700 dark:text-white',
  GRAY_600: 'text-grey-600 dark:text-white',
  GRAY_500: 'text-grey-500 dark:text-white',
  GRAY_400: 'text-grey-400 dark:text-grey-100',
} as const satisfies Record<string, string>

//====================
// TYPE COLORS
//====================

export type Color = {
  label: string
  value: {
    color: string
    className: string | null
  }
  LIGHT?: Color
  DARK?: Color
}

type Colors = Record<string, Color>

//====================
// BG COLORS
//====================

export const bgColors = {
  WHITE: {
    label: 'White',
    value: {
      color: '#fff',
      className: null,
    },
  },
  RED: {
    label: picoColors.RED.label,
    value: {
      color: picoColors.RED.color500,
      className: 'pico-background-red-500',
    },
    LIGHT: {
      label: picoColors.RED.LIGHT.label,
      value: {
        color: picoColors.RED.LIGHT.color500,
        className: 'pico-background-red-50',
      },
    },
    DARK: {
      label: picoColors.RED.DARK.label,
      value: {
        color: picoColors.RED.DARK.color500,
        className: 'pico-background-red-800',
      },
    },
  },
  PINK: {
    label: picoColors.PINK.label,
    value: {
      color: picoColors.PINK.color500,
      className: 'pico-background-pink-500',
    },
    LIGHT: {
      label: picoColors.PINK.LIGHT.label,
      value: {
        color: picoColors.PINK.LIGHT.color500,
        className: 'pico-background-pink-50',
      },
    },
    DARK: {
      label: picoColors.PINK.DARK.label,
      value: {
        color: picoColors.PINK.DARK.color500,
        className: 'pico-background-pink-800',
      },
    },
  },
  FUCHSIA: {
    label: picoColors.FUCHSIA.label,
    value: {
      color: picoColors.FUCHSIA.color500,
      className: 'pico-background-fuchsia-500',
    },
    LIGHT: {
      label: picoColors.FUCHSIA.LIGHT.label,
      value: {
        color: picoColors.FUCHSIA.LIGHT.color500,
        className: 'pico-background-fuchsia-50',
      },
    },
    DARK: {
      label: picoColors.FUCHSIA.DARK.label,
      value: {
        color: picoColors.FUCHSIA.DARK.color500,
        className: 'pico-background-fuchsia-800',
      },
    },
  },
  PURPLE: {
    label: picoColors.PURPLE.label,
    value: {
      color: picoColors.PURPLE.color500,
      className: 'pico-background-purple-500',
    },
    LIGHT: {
      label: picoColors.PURPLE.LIGHT.label,
      value: {
        color: picoColors.PURPLE.LIGHT.color500,
        className: 'pico-background-purple-50',
      },
    },
    DARK: {
      label: picoColors.PURPLE.DARK.label,
      value: {
        color: picoColors.PURPLE.DARK.color500,
        className: 'pico-background-purple-800',
      },
    },
  },
  VIOLET: {
    label: picoColors.VIOLET.label,
    value: {
      color: picoColors.VIOLET.color500,
      className: 'pico-background-violet-500',
    },
    LIGHT: {
      label: picoColors.VIOLET.LIGHT.label,
      value: {
        color: picoColors.VIOLET.LIGHT.color500,
        className: 'pico-background-violet-50',
      },
    },
    DARK: {
      label: picoColors.VIOLET.DARK.label,
      value: {
        color: picoColors.VIOLET.DARK.color500,
        className: 'pico-background-violet-800',
      },
    },
  },
  INDIGO: {
    label: picoColors.INDIGO.label,
    value: {
      color: picoColors.INDIGO.color500,
      className: 'pico-background-indigo-500',
    },
    LIGHT: {
      label: picoColors.INDIGO.LIGHT.label,
      value: {
        color: picoColors.INDIGO.LIGHT.color500,
        className: 'pico-background-indigo-50',
      },
    },
    DARK: {
      label: picoColors.INDIGO.DARK.label,
      value: {
        color: picoColors.INDIGO.DARK.color500,
        className: 'pico-background-indigo-800',
      },
    },
  },
  BLUE: {
    label: picoColors.BLUE.label,
    value: {
      color: picoColors.BLUE.color500,
      className: 'pico-background-blue-500',
    },
    LIGHT: {
      label: picoColors.BLUE.LIGHT.label,
      value: {
        color: picoColors.BLUE.LIGHT.color500,
        className: 'pico-background-blue-50',
      },
    },
    DARK: {
      label: picoColors.BLUE.DARK.label,
      value: {
        color: picoColors.BLUE.DARK.color500,
        className: 'pico-background-blue-800',
      },
    },
  },
  AZURE: {
    label: picoColors.AZURE.label,
    value: {
      color: picoColors.AZURE.color500,
      className: 'pico-background-azure-500',
    },
    LIGHT: {
      label: picoColors.AZURE.LIGHT.label,
      value: {
        color: picoColors.AZURE.LIGHT.color500,
        className: 'pico-background-azure-50',
      },
    },
    DARK: {
      label: picoColors.AZURE.DARK.label,
      value: {
        color: picoColors.AZURE.DARK.color500,
        className: 'pico-background-azure-800',
      },
    },
  },
  CYAN: {
    label: picoColors.CYAN.label,
    value: {
      color: picoColors.CYAN.color500,
      className: 'pico-background-cyan-500',
    },
    LIGHT: {
      label: picoColors.CYAN.LIGHT.label,
      value: {
        color: picoColors.CYAN.LIGHT.color500,
        className: 'pico-background-cyan-50',
      },
    },
    DARK: {
      label: picoColors.CYAN.DARK.label,
      value: {
        color: picoColors.CYAN.DARK.color500,
        className: 'pico-background-cyan-800',
      },
    },
  },
  JADE: {
    label: picoColors.JADE.label,
    value: {
      color: picoColors.JADE.color500,
      className: 'pico-background-jade-500',
    },
    LIGHT: {
      label: picoColors.JADE.LIGHT.label,
      value: {
        color: picoColors.JADE.LIGHT.color500,
        className: 'pico-background-jade-50',
      },
    },
    DARK: {
      label: picoColors.JADE.DARK.label,
      value: {
        color: picoColors.JADE.DARK.color500,
        className: 'pico-background-jade-800',
      },
    },
  },
  GREEN: {
    label: picoColors.GREEN.label,
    value: {
      color: picoColors.GREEN.color500,
      className: 'pico-background-green-500',
    },
    LIGHT: {
      label: picoColors.GREEN.LIGHT.label,
      value: {
        color: picoColors.GREEN.LIGHT.color500,
        className: 'pico-background-green-50',
      },
    },
    DARK: {
      label: picoColors.GREEN.DARK.label,
      value: {
        color: picoColors.GREEN.DARK.color500,
        className: 'pico-background-green-800',
      },
    },
  },
  LIME: {
    label: picoColors.LIME.label,
    value: {
      color: picoColors.LIME.color500,
      className: 'pico-background-lime-500',
    },
    LIGHT: {
      label: picoColors.LIME.LIGHT.label,
      value: {
        color: picoColors.LIME.LIGHT.color500,
        className: 'pico-background-lime-50',
      },
    },
    DARK: {
      label: picoColors.LIME.DARK.label,
      value: {
        color: picoColors.LIME.DARK.color500,
        className: 'pico-background-lime-800',
      },
    },
  },
  YELLOW: {
    label: picoColors.YELLOW.label,
    value: {
      color: picoColors.YELLOW.color500,
      className: 'pico-background-yellow-500',
    },
    LIGHT: {
      label: picoColors.YELLOW.LIGHT.label,
      value: {
        color: picoColors.YELLOW.LIGHT.color500,
        className: 'pico-background-yellow-50',
      },
    },
    DARK: {
      label: picoColors.YELLOW.DARK.label,
      value: {
        color: picoColors.YELLOW.DARK.color500,
        className: 'pico-background-yellow-800',
      },
    },
  },
  AMBER: {
    label: picoColors.AMBER.label,
    value: {
      color: picoColors.AMBER.color500,
      className: 'pico-background-amber-500',
    },
    LIGHT: {
      label: picoColors.AMBER.LIGHT.label,
      value: {
        color: picoColors.AMBER.LIGHT.color500,
        className: 'pico-background-amber-50',
      },
    },
    DARK: {
      label: picoColors.AMBER.DARK.label,
      value: {
        color: picoColors.AMBER.DARK.color500,
        className: 'pico-background-amber-800',
      },
    },
  },
  PUMPKIN: {
    label: picoColors.PUMPKIN.label,
    value: {
      color: picoColors.PUMPKIN.color500,
      className: 'pico-background-pumpkin-500',
    },
    LIGHT: {
      label: picoColors.PUMPKIN.LIGHT.label,
      value: {
        color: picoColors.PUMPKIN.LIGHT.color500,
        className: 'pico-background-pumpkin-50',
      },
    },
    DARK: {
      label: picoColors.PUMPKIN.DARK.label,
      value: {
        color: picoColors.PUMPKIN.DARK.color500,
        className: 'pico-background-pumpkin-800',
      },
    },
  },
  ORANGE: {
    label: picoColors.ORANGE.label,
    value: {
      color: picoColors.ORANGE.color500,
      className: 'pico-background-orange-500',
    },
    LIGHT: {
      label: picoColors.ORANGE.LIGHT.label,
      value: {
        color: picoColors.ORANGE.LIGHT.color500,
        className: 'pico-background-orange-50',
      },
    },
    DARK: {
      label: picoColors.ORANGE.DARK.label,
      value: {
        color: picoColors.ORANGE.DARK.color500,
        className: 'pico-background-orange-800',
      },
    },
  },
  SAND: {
    label: picoColors.SAND.label,
    value: {
      color: picoColors.SAND.color500,
      className: 'pico-background-sand-500',
    },
    LIGHT: {
      label: picoColors.SAND.LIGHT.label,
      value: {
        color: picoColors.SAND.LIGHT.color500,
        className: 'pico-background-sand-50',
      },
    },
    DARK: {
      label: picoColors.SAND.DARK.label,
      value: {
        color: picoColors.SAND.DARK.color500,
        className: 'pico-background-sand-800',
      },
    },
  },
  GREY: {
    label: picoColors.GREY.label,
    value: {
      color: picoColors.GREY.color500,
      className: 'pico-background-grey-500',
    },
    LIGHT: {
      label: picoColors.GREY.LIGHT.label,
      value: {
        color: picoColors.GREY.LIGHT.color500,
        className: 'pico-background-grey-50',
      },
    },
    DARK: {
      label: picoColors.GREY.DARK.label,
      value: {
        color: picoColors.GREY.DARK.color500,
        className: 'pico-background-grey-800',
      },
    },
  },
  ZINC: {
    label: picoColors.ZINC.label,
    value: {
      color: picoColors.ZINC.color500,
      className: 'pico-background-zinc-500',
    },
    LIGHT: {
      label: picoColors.ZINC.LIGHT.label,
      value: {
        color: picoColors.ZINC.LIGHT.color500,
        className: 'pico-background-zinc-50',
      },
    },
    DARK: {
      label: picoColors.ZINC.DARK.label,
      value: {
        color: picoColors.ZINC.DARK.color500,
        className: 'pico-background-zinc-800',
      },
    },
  },
  SLATE: {
    label: picoColors.SLATE.label,
    value: {
      color: picoColors.SLATE.color500,
      className: 'pico-background-slate-500',
    },
    LIGHT: {
      label: picoColors.SLATE.LIGHT.label,
      value: {
        color: picoColors.SLATE.LIGHT.color500,
        className: 'pico-background-slate-50',
      },
    },
    DARK: {
      label: picoColors.SLATE.DARK.label,
      value: {
        color: picoColors.SLATE.DARK.color500,
        className: 'pico-background-slate-800',
      },
    },
  },
} as const satisfies Colors
