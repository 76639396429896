// extracted by mini-css-extract-plugin
export var paddingBottomLarge = "Section-module--padding-bottom-large--65bb5";
export var paddingBottomMedium = "Section-module--padding-bottom-medium--4281c";
export var paddingBottomNone = "Section-module--padding-bottom-none--04faa";
export var paddingBottomSmall = "Section-module--padding-bottom-small--d4414";
export var paddingBottomXlarge = "Section-module--padding-bottom-xlarge--621ce";
export var paddingTopLarge = "Section-module--padding-top-large--20577";
export var paddingTopMedium = "Section-module--padding-top-medium--70105";
export var paddingTopNone = "Section-module--padding-top-none--6d518";
export var paddingTopSmall = "Section-module--padding-top-small--00c78";
export var paddingTopXlarge = "Section-module--padding-top-xlarge--2e84d";
export var section = "Section-module--section--911fb";