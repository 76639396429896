import React from 'react'
import {
  Text,
  RichText,
  Image,
  File,
  Repeater,
  types,
} from 'react-bricks/frontend'

import Section from '../shared/components/Section'
import Container from '../shared/components/Container'
import Grid from '../shared/components/Grid'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import blockNames from '../blockNames'
import { ButtonProps } from '../shared/bricks/Button'

import * as style from 'css/HorizontalHero.module.css'
import classNames from 'classnames'
import { sectionLayoutSideGroup } from '../sideEditProps'
import { SectionProps } from '../shared/components/Section'
import { horizontalHeroDefaultProps } from '../defaultProps'
import { RichTextProps } from 'src/react-bricks/customTypes'
export interface HorizontalHeroProps {
  title: RichTextProps[]
  text: RichTextProps[]
  buttons: ButtonProps[]
  backgroundImage?: any
  overlay?: string
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
}

const HorizontalHero: types.Brick<HorizontalHeroProps> = ({
  backgroundImage,
  overlay,
  paddingBottom,
  paddingTop,
}) => {
  return (
    <EditorBrickContainer>
      <Section
        className={style.section}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      >
        {backgroundImage && (
          <>
            <Image
              propName="backgroundImage"
              alt={backgroundImage.alt || 'Background image'}
              maxWidth={1920}
              imageClassName={style.backgroundImage}
            />
            {overlay && overlay !== 'none' && (
              <div
                className={
                  overlay === 'dark'
                    ? classNames(style.overlay, style.overlayDark)
                    : classNames(style.overlay, style.overlayLight)
                }
              ></div>
            )}
          </>
        )}
        <Container
          theme={backgroundImage && overlay === 'dark' ? 'dark' : null}
        >
          <Grid>
            <div className={style.titleContainer}>
              <RichText
                propName="title"
                placeholder="Type a title..."
                allowedFeatures={[types.RichTextFeatures.Highlight]}
                // renderHighlight={({ children }) => <span className={style.highlight}>{children}</span>}
                renderBlock={({ children }) => (
                  <h1 className={style.title}>{children}</h1>
                )}
              />
            </div>
            <div className={style.textContainer}>
              <RichText
                propName="text"
                placeholder="Type a text..."
                allowedFeatures={[
                  types.RichTextFeatures.Bold,
                  types.RichTextFeatures.UnorderedList,
                  types.RichTextFeatures.Link,
                ]}
              />
              <Repeater
                propName="buttons"
                renderWrapper={(items) => (
                  <div className={style.buttonContainer}>{items}</div>
                )}
              />
            </div>
          </Grid>
        </Container>
      </Section>
    </EditorBrickContainer>
  )
}

HorizontalHero.schema = {
  name: blockNames.HorizontalHero,
  label: 'Horizontal Hero',
  category: 'Heros',
  tags: ['Hero'],
  previewImageUrl: `/bricks/${blockNames.HorizontalHero}.png`,
  getDefaultProps: () => horizontalHeroDefaultProps,
  repeaterItems: [
    {
      name: 'buttons',
      itemType: blockNames.Button,
      itemLabel: 'Button',
      min: 0,
      max: 2,
    },
  ],
  sideEditProps: [
    sectionLayoutSideGroup,
    {
      name: 'backgroundImage',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
    },
    {
      name: 'overlay',
      label: 'Overlay',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { value: 'none', label: 'None' },
          { value: 'dark', label: 'Dark' },
          { value: 'light', label: 'Light' },
        ],
      },
      show(props, page, user) {
        return !!props.backgroundImage
      },
    },
  ],
}

export default HorizontalHero
