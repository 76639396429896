import React from 'react'
import { types, useAdminContext } from 'react-bricks/frontend'
import { Text } from 'react-bricks/frontend'
import * as style from 'css/Sidebar.module.css'
import blockNames from '../blockNames'
import { Link } from 'gatsby'
import Section from '../shared/components/Section'
import Button from '../shared/bricks/Button'

interface SidebarProps {}

const Sidebar: types.Brick<SidebarProps> = () => {
  const { isAdmin } = useAdminContext()

  return (
    <div className={style.sidebarContainer}>
      <article className={style.sidebarCard}>
        <header>
          <h2>Sidebar</h2>
        </header>
        test
        <footer>
          <button>Save</button>
        </footer>
      </article>
    </div>
  )
}

export default Sidebar

Sidebar.schema = {
  name: blockNames.Sidebar,
  label: 'Sidebar',
  category: 'layout',
  tags: ['Navigation'],
  previewImageUrl: `/bricks/${blockNames.Sidebar}.png`,
  repeaterItems: [],
  sideEditProps: [],
}
