const blogPostTags = [
  { value: 'uncategorized', label: 'Uncategorized' },
  { value: 'cash-home-buying', label: 'Cash Home Buying' },
  { value: 'foreclosure-and-tax-solutions', label: 'Foreclosure and Tax Solutions' },
  { value: 'quick-and-as-is-home-sales', label: 'Quick and As-Is Home Sales' },
  { value: 'real-estate-selling-strategies', label: 'Real Estate Selling Strategies' },
  { value: 'estate-and-inheritance-guidance', label: 'Estate and Inheritance Guidance' },
  { value: 'market-insights-and-trends', label: 'Market Insights and Trends' },
  { value: 'home-selling-advice', label: 'Home Selling Advice' },
  { value: 'financial-and-tax-advice', label: 'Financial and Tax Advice' },

]

export default blogPostTags
