// extracted by mini-css-extract-plugin
export var grid = "Grid-module--grid--12453";
export var grid1 = "Grid-module--grid-1--c599c";
export var grid10 = "Grid-module--grid-10--5dc2b";
export var grid11 = "Grid-module--grid-11--a8f46";
export var grid12 = "Grid-module--grid-12--783e1";
export var grid2 = "Grid-module--grid-2--fd069";
export var grid3 = "Grid-module--grid-3--87f31";
export var grid4 = "Grid-module--grid-4--5705e";
export var grid5 = "Grid-module--grid-5--03bca";
export var grid6 = "Grid-module--grid-6--8ff48";
export var grid7 = "Grid-module--grid-7--e8f19";
export var grid8 = "Grid-module--grid-8--4090b";
export var grid9 = "Grid-module--grid-9--f10f5";