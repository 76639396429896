import React from 'react'
import { Link, Repeater, Text, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'

export interface FooterLinkProps {
  title: string
  href: string
}

const FooterLink: types.Brick<FooterLinkProps> = ({ href }) => {
  return (
    <Link href={href} className='secondary'>
      <Text
        propName="title"
        placeholder="Type a title..."
        renderBlock={({ children }) => <span>{children}</span>}
      />
    </Link>
  )
}

export default FooterLink

FooterLink.schema = {
  name: blockNames.FooterLink,
  label: 'Footer Link',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    title: 'About Us',
    href: '/',
  }),
  sideEditProps: [
    {
      name: 'href',
      label: 'Link',
      type: types.SideEditPropType.Text,
    },
  ],
}
