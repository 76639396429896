import { types } from 'react-bricks/frontend'
// Bricks
import heros from './Hero'

// Shared bricks
import Button from './shared/bricks/Button'
import Header from './layout/Header'
import HeaderMenuItem from './layout/HeaderMenuItem'
import HeaderMenuSubItem from './layout/HeaderMenuSubItem'
import Footer from './layout/Footer'
import FooterColumn from './layout/FooterColumn'
import FooterLink from './layout/FooterLink'
import TextImage from './TextImage'
import FeatureGrid from './FeatureGrid'
import Heading from './Heading'
import NavigationCards from './NavigationCards'
import Form from './shared/bricks/Form'
import TextForm from './TextForm'
import CtaBanner from './Cta/CtaBanner'
import TeamMemberContent from './team-member/Content'
import Paragraph from './Paragraph'
import Accordions from './Accordions'
import FormSection from './FormSection'
import ImageSection from './ImageSection'
import Sidebar from './layout/Sidebar'
// Theme structure
const allBricks: types.Theme = {
  themeName: 'Pico CSS UI',
  categories: [
    {
      categoryName: 'Heros',
      bricks: [...heros],
    },
    {
      categoryName: 'Forms',
      bricks: [...FormSection],
    },
    {
      categoryName: 'Main',
      bricks: [
        TextImage,
        ...FeatureGrid,
        Heading,
        ...NavigationCards,
        TextForm,
        CtaBanner,
        Paragraph,
        ImageSection,
        ...Accordions,
      ],
    },
    {
      categoryName: 'Shared',
      bricks: [Button],
    },
    {
      categoryName: 'Layout',
      bricks: [
        Header,
        HeaderMenuItem,
        HeaderMenuSubItem,
        Footer,
        FooterColumn,
        FooterLink,
        Sidebar,
      ],
    },
    // {
    //   categoryName: 'Entities',
    //   bricks: [TeamMemberContent],
    // },
  ],
}

export default allBricks
