import React from 'react'
import blockNames from '../blockNames'
import classNames from 'classnames'
import { Text, Repeater, types, Link } from 'react-bricks/frontend'
import * as style from 'css/HeaderMenuItem.module.css'
import { HeaderMenuSubItemProps } from './HeaderMenuSubItem'

export interface MenuItems {
  linkPath: string
  linkText: string
  mobileOnly?: boolean
  submenuItems?: HeaderMenuSubItemProps[]
}

interface HeaderMenuItemProps extends MenuItems {
  mobileRef: React.MutableRefObject<HTMLDivElement>
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderMenuItem: types.Brick<HeaderMenuItemProps> = ({
  linkPath,
  submenuItems,
  mobileOnly = false,
}) => {
  if (!submenuItems || !submenuItems.length) {
    return (
      <Link href={linkPath}>
        <Text
          propName="linkText"
          placeholder="Type a text..."
          renderBlock={({ children }) => <span>{children}</span>}
        />
      </Link>
    )
  }

  return (
    <details
      className={classNames({
        dropdown: !mobileOnly,
        [style.mobileSubmenu]: mobileOnly,
      })}
    >
      <summary>
        <Text
          propName="linkText"
          placeholder="Type a text..."
          renderBlock={({ children }) => <span>{children}</span>}
        />
      </summary>
      <ul dir="rtl">
        <Repeater
          propName="submenuItems"
          renderItemWrapper={(item) => <li key={item.key}>{item}</li>}
        />
      </ul>
    </details>
  )
}

HeaderMenuItem.schema = {
  name: blockNames.HeaderMenuItem,
  label: 'Menu Item',
  category: 'layout',
  hideFromAddMenu: true,

  repeaterItems: [
    {
      name: 'submenuItems',
      itemType: blockNames.HeaderMenuSubItem,
    },
  ],

  getDefaultProps: () => ({
    linkPath: '/about-us',
    linkText: 'About us',
  }),

  sideEditProps: [
    {
      name: 'linkPath',
      label: 'Link to...',
      type: types.SideEditPropType.Text,
    },
  ],
}

export default HeaderMenuItem
