import React from 'react'
import {
  Text,
  RichText,
  Image,
  File,
  Repeater,
  types,
} from 'react-bricks/frontend'
import blockNames from '../blockNames'
import * as style from 'css/NavigationCard.module.css'
import { overlaySideEditProps } from '../sideEditProps'
import classNames from 'classnames'
import { ButtonProps } from '../shared/bricks/Button'
import { NavigationCardDefaultProps } from '../defaultProps'
export interface NavigationCardProps {
  title: string
  text: any
  image?: types.IImageSource
  overlay: 'none' | 'dark' | 'light'
  buttons: ButtonProps[]
}

const NavigationCard: types.Brick<NavigationCardProps> = (props) => {
  return (
    <article className={style.card} data-theme={props.overlay}>
      {props.image ? (
        <>
          <Image
            propName="image"
            alt={props.image?.alt}
            imageClassName={style.image}
          />
          <div
            className={classNames({
              [style.darkOverlay]: props.overlay === 'dark',
              [style.lightOverlay]: props.overlay === 'light',
            })}
          ></div>
        </>
      ) : null}
      <div className={style.content}>
        <div>
          <Text
            propName="title"
            placeholder="Title..."
            renderBlock={({ children }) => <h2>{children}</h2>}
          />
          <RichText
            propName="text"
            placeholder="Text..."
            allowedFeatures={[
              types.RichTextFeatures.Bold,
              types.RichTextFeatures.Italic,
            ]}
          />
        </div>
        <div className={style.buttonContainer}>
          <Repeater propName="buttons" />
        </div>
      </div>
    </article>
  )
}

NavigationCard.schema = {
  name: blockNames.NavigationCard,
  label: 'Navigation Card',
  previewImageUrl: `/bricks/${blockNames.NavigationCard}.png`,
  hideFromAddMenu: true,
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => NavigationCardDefaultProps,

  repeaterItems: [
    {
      name: 'buttons',
      itemType: blockNames.Button,
      itemLabel: 'Button',
      min: 1,
      max: 1,
    },
  ],

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'image',
      label: 'Image',
      type: types.SideEditPropType.Image,
    },
    ...overlaySideEditProps,
  ],
}

export default NavigationCard
