import React from 'react'
import { Link, Text, types } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
import classNames from 'classnames'
import * as style from 'css/Button.module.css'
import { navigate } from 'gatsby'
import { ButtonDefaultProps } from '../../defaultProps'

export interface ButtonProps {
  text?: string
  href: string
  openInNewTab?: boolean
  variant:
    | 'primary'
    | 'secondary'
    | 'contrast'
    | 'primary outline'
    | 'secondary outline'
    | 'contrast outline'
    | 'link'
  linkType?: 'internal-link' | 'external-link' | 'email' | 'phone' | 'anchor'
  fullWidth?: boolean
  useAsButton?: boolean
  umamiEnabled?: boolean
  umamiEventName?: string
  className?: string
}

const Button: types.Brick<ButtonProps> = ({
  href = '',
  openInNewTab = false,
  variant = 'primary',
  linkType = 'external-link',
  umamiEnabled = false,
  umamiEventName = '',
  useAsButton = false,
  fullWidth = false,
  className,
}) => {
  const classes = classNames(className, {
    primary: variant === 'primary',
    secondary: variant === 'secondary',
    contrast: variant === 'contrast',
    'primary outline': variant === 'primary outline',
    'secondary outline': variant === 'secondary outline',
    'contrast outline': variant === 'contrast outline',
    [style.link]: variant === 'link',
    [style.fullWidth]: fullWidth,
  })

  const target = openInNewTab ? '_blank' : '_self'
  let hrefValue = href
  switch (linkType) {
    case 'email':
      hrefValue = `mailto:${href}`
      break
    case 'phone':
      hrefValue = `tel:${href}`
      break
    default:
      break
  }

  if (useAsButton) {
    const onClick = () => {
      if (linkType === 'internal-link') {
        navigate(href)
      }
      if (typeof window !== 'undefined') {
        window.open(href, target)
      }
    }

    return (
      <button
        className={classes}
        type="button"
        data-umami-event={umamiEnabled ? umamiEventName : undefined}
        data-umami-page-origin={
          umamiEnabled && typeof window !== 'undefined'
            ? window.location.href
            : undefined
        }
        data-umami-button-href={umamiEnabled ? href : undefined}
        onClick={onClick}
      >
        <Text
          propName="text"
          placeholder="Type a text..."
          renderBlock={({ children }) => <span>{children}</span>}
          renderPlaceholder={() => <span>Lorem ipsum</span>}
        />
      </button>
    )
  }

  return (
    <Link
      href={hrefValue}
      target={target}
      className={classes}
      type="button"
      data-umami-event={umamiEnabled ? umamiEventName : undefined}
      data-umami-page-origin={
        umamiEnabled && typeof window !== 'undefined'
          ? window.location.href
          : undefined
      }
      data-umami-button-href={umamiEnabled ? href : undefined}
    >
      <Text
        propName="text"
        placeholder="Type a text..."
        renderBlock={({ children }) => <span>{children}</span>}
        renderPlaceholder={() => <span>Lorem ipsum</span>}
      />
    </Link>
  )
}

Button.schema = {
  name: blockNames.Button,
  label: 'Button',
  category: 'Shared',
  tags: ['Button', 'Link', 'CTA'],
  hideFromAddMenu: true,
  getDefaultProps: () => ButtonDefaultProps,
  sideEditProps: [
    {
      name: 'linkType',
      label: 'Link type',
      helperText:
        'Choose the type of link. Internal URL is a link to a page of your website. External URL is a link to another website. Email is a mailto link. Phone is a tel link. Anchor is a link to a specific section of the page.',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { value: 'internal-link', label: 'Internal URL' },
          { value: 'external-link', label: 'External URL' },
          { value: 'email', label: 'Email' },
          { value: 'phone', label: 'Phone' },
          { value: 'anchor', label: 'Anchor' },
        ],
      },
    },
    {
      name: 'href',
      label: 'Link',
      type: types.SideEditPropType.Text,
      validate(value, props) {
        // Check if the value is empty first, since this is common across all conditions
        if (!value) return 'URL cannot be empty'

        // Check based on the type of link
        switch (props.linkType) {
          case 'internal-link':
            // Internal links must start with '/'
            if (!value.startsWith('/')) {
              return "Internal URL must start with '/'"
            }
            if (value.includes(' ')) {
              return 'Internal URL cannot contain spaces'
            }
            break
          case 'external-link':
            // External links must start with 'http' or 'https'
            if (!value.startsWith('http')) {
              return "External URL must start with 'http'"
            }
            break
          case 'email':
            // Emails must contain '@'
            if (!value.includes('@')) {
              return "Email must contain '@'"
            }
            break
          case 'phone':
            // Basic validation for phone numbers, can be extended to more specific formats
            if (!/^\+?(\d.*){3,}$/.test(value)) {
              return 'Phone must be a valid phone number'
            }
            break
          case 'anchor':
            // Anchor links must start with '#'
            if (!value.startsWith('#')) {
              return "Anchor must start with '#'"
            }
            break
          default:
            // Handling unexpected link type by indicating an invalid type
            return 'Invalid link type'
        }

        // If all conditions are met and no breaks occurred with a return, the value is valid
        return true
      },
    },
    {
      name: 'openInNewTab',
      label: 'Open in new tab',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'variant',
      label: 'Variant',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { value: 'primary', label: 'Primary' },
          { value: 'secondary', label: 'Secondary' },
          { value: 'contrast', label: 'Contrast' },
          { value: 'primary outline', label: 'Primary outline' },
          { value: 'secondary outline', label: 'Secondary outline' },
          { value: 'contrast outline', label: 'Contrast outline' },
          { value: 'link', label: 'Link' },
        ],
      },
    },
    {
      name: 'umamiEnabled',
      label: 'Enable Umami tracking',
      type: types.SideEditPropType.Boolean,
      helperText: 'Enable tracking this button with Umami analytics',
    },
    {
      name: 'umamiEventName',
      label: 'Event name',
      type: types.SideEditPropType.Text,
      helperText: 'The event name to track in Umami',
      validate(value, props) {
        if (props.umamiEnabled && !value) {
          return 'Event name is required'
        }
        return true
      },
      show(props) {
        return props.umamiEnabled
      },
    },
  ],
}

export default Button
