import React from 'react'
import { RichText, types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import Section, { SectionProps } from '../shared/components/Section'
import { sectionLayoutSideGroup } from '../sideEditProps'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import { RichTextProps } from 'src/react-bricks/customTypes'
import { paragraphDefaultProps } from '../defaultProps'
export interface ParagraphProps {
  text: RichTextProps[]
  paddingTop?: SectionProps['paddingTop']
  paddingBottom?: SectionProps['paddingBottom']
  backgroundColor?: SectionProps['backgroundColor']
}

const Paragraph: types.Brick<ParagraphProps> = (props) => {
  return (
    <EditorBrickContainer>
      <Section
        container
        paddingTop={props.paddingTop}
        paddingBottom={props.paddingBottom}
        backgroundColor={props.backgroundColor}
      >
        <RichText
          propName="text"
          placeholder="Type something..."
          allowedFeatures={[
            types.RichTextFeatures.Bold,
            types.RichTextFeatures.Italic,
            types.RichTextFeatures.Link,
            types.RichTextFeatures.Code,
            types.RichTextFeatures.OrderedList,
            types.RichTextFeatures.UnorderedList,
            types.RichTextFeatures.Heading1,
            types.RichTextFeatures.Heading2,
            types.RichTextFeatures.Heading3,
            types.RichTextFeatures.Heading4,
            types.RichTextFeatures.Heading5,
            types.RichTextFeatures.Heading6,
            types.RichTextFeatures.Quote,
            types.RichTextFeatures.Highlight
          ]}
        />
      </Section>
    </EditorBrickContainer>
  )
}

Paragraph.schema = {
  name: blockNames.Paragraph,
  label: 'Paragraph',
  category: 'Main',
  previewImageUrl: `/bricks/${blockNames.Paragraph}.png`,
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => paragraphDefaultProps,

  // Sidebar Edit controls for props
  sideEditProps: [sectionLayoutSideGroup],
}

export default Paragraph
