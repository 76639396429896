import React from 'react'
import classNames from 'classnames'
import * as styles from '../../../../../css/Flex.module.css'

interface FlexProps {
  flexFlow?: 'row' | 'column'
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  flexWrap?: 'wrap' | 'nowrap'
  gap?: number | string
  columnGap?: number | string
  rowGap?: number | string
  children: React.ReactNode
  className?: string
  key?: string
}

function Flex(props: FlexProps) {
  return (
    <div
      className={classNames(props.className, styles.flex)}
      style={{
        flexFlow: props.flexFlow,
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
        flexWrap: props.flexWrap,
        gap: `${props.gap}rem`,
        columnGap: `${props.columnGap}rem`,
        rowGap: `${props.rowGap}rem`,
      }}
      key={props.key}
    >
      {props.children}
    </div>
  )
}

interface ItemProps {
  children: React.ReactNode
  className?: string
  key?: string
  width?: number
  flexGrow?: number
  flexShrink?: number
  flexBasis?: number
  order?: number
  alignSelf?: 'auto' | 'flex-start' | 'center' | 'flex-end' | 'stretch'
}

function Item(props: ItemProps) {
  return (
    <div
      className={classNames(props.className, styles.item)}
      style={{
        width: props.width,
        flexGrow: props.flexGrow,
        flexShrink: props.flexShrink,
        flexBasis: props.flexBasis,
        order: props.order,
        alignSelf: props.alignSelf,
      }}
      key={props.key}
    >
      {props.children}
    </div>
  )
}

Flex.Item = Item

export default Flex
