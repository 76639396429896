import { types } from 'react-bricks/frontend'
import blockNames from './bricks/pico-ui/blockNames'
import blogPostTags from './blogPostTags'

const pageTypes: types.IPageType[] = [
  {
    name: 'page',
    pluralName: 'pages',
    defaultLocked: false,
    defaultStatus: types.PageStatus.Published,
    excludedBlockTypes: [
      blockNames.Footer,
      blockNames.Header,
      blockNames.Sidebar,
      // blockNames.TeamMemberContent,
    ],
    getDefaultContent: () => [],
  },
  {
    name: 'blog_post',
    pluralName: 'Blog Posts',
    defaultStatus: types.PageStatus.Published,
    customFields: [
      {
        name: 'postCategory',
        label: 'Post Category',
        type: types.SideEditPropType.Select,
        selectOptions: {
          display: types.OptionsDisplay.Select,
          options: blogPostTags,
        },
      },
      {
        name: 'readingTime',
        label: 'Reading Time (minutes)',
        type: types.SideEditPropType.Number,
        validate: (value) => value >= 5,
        rangeOptions: {
          min: 1,
          step: 1,
        },
      },
      {
        name: 'featuredPost',
        label: 'Featured Post',
        type: types.SideEditPropType.Boolean,
      },
      {
        name: 'contentSummary',
        label: 'Content Summary',
        type: types.SideEditPropType.Textarea,
      },
    ],
    getDefaultContent: () => [blockNames.CtaBanner],
    allowedBlockTypes: [
      blockNames.ImageSection,
      blockNames.Paragraph,
      blockNames.CtaBanner,
      blockNames.FormSection,
    ],
  },
  {
    name: 'layout',
    pluralName: 'layout',
    defaultStatus: types.PageStatus.Published,
    isEntity: true,
  },
  // {
  //   name: 'form',
  //   pluralName: 'forms',
  //   defaultLocked: false,
  //   defaultStatus: types.PageStatus.Published,
  //   isEntity: true,
  //   allowedBlockTypes: ['form'],
  // },
  // {
  //   name: 'teamMember',
  //   pluralName: 'Team Members',
  //   defaultLocked: true,
  //   defaultStatus: types.PageStatus.Published,
  //   allowedBlockTypes: [blockNames.TeamMemberContent],
  //   getDefaultContent: () => [blockNames.TeamMemberContent],
  //   slugPrefix: {
  //     default: 'team/',
  //   },
  // },
]

export default pageTypes
