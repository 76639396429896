import React from 'react'
import { Text, RichText, Image, types } from 'react-bricks/frontend'
import EditorBrickContainer from '../shared/components/EditorBrickContainer'
import blockNames from '../blockNames'
import classNames from 'classnames'
import * as style from 'css/TextImage.module.css'
import { sectionLayoutSideGroup } from '../sideEditProps'
import Section, { SectionProps } from '../shared/components/Section'
interface TextImageProps {
  title: string
  content: any
  image: types.IImageSource
  imagePosition: 'left' | 'right'
  paddingTop: SectionProps['paddingTop']
  paddingBottom: SectionProps['paddingBottom']
  backgroundColor: SectionProps['backgroundColor']
}

const TextImage: types.Brick<TextImageProps> = ({
  image,
  imagePosition,
  paddingBottom,
  paddingTop,
  backgroundColor
}) => {
  return (
    <EditorBrickContainer>
      <Section
        container
        className={classNames('grid', style.sectionContainer)}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        backgroundColor={backgroundColor}
      >
        <article
          className={classNames(style.imageContainer, {
            [style.order1]: imagePosition === 'left',
            [style.order2]: imagePosition === 'right',
          })}
        >
          <Image
            propName="image"
            alt={image?.alt}
            noLazyLoad
            imageClassName={style.image}
            maxWidth={500}
          />
        </article>
        <div
          className={classNames(style.textContainer, {
            [style.order1]: imagePosition === 'right',
            [style.order2]: imagePosition === 'left',
          })}
        >
          <Text
            propName="title"
            placeholder="Title..."
            renderBlock={({ children }) => <h2>{children}</h2>}
          />
          <RichText
            propName="content"
            placeholder="Content..."
            allowedFeatures={[
              types.RichTextFeatures.Bold,
              types.RichTextFeatures.Link,
              types.RichTextFeatures.OrderedList,
              types.RichTextFeatures.UnorderedList,
              types.RichTextFeatures.Heading3,
              types.RichTextFeatures.Heading4,
              types.RichTextFeatures.Heading5,
              types.RichTextFeatures.Heading6,
            ]}
          />
        </div>
      </Section>
    </EditorBrickContainer>
  )
}

TextImage.schema = {
  name: blockNames.TextImage,
  label: 'TextImage',
  category: 'Main',
  // tags: [],
  previewImageUrl: `/bricks/${blockNames.TextImage}.png`,
  // Defaults when a new brick is added
  getDefaultProps: () => ({
    paddingTop: 'medium',
    paddingBottom: 'medium',
    title: 'We Buy Houses In Florida',
    content: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Sell Your House And Pay No Agent Fees Or Costly Repairs. Find Out How ',
          },
          {
            type: 'link',
            data: {
              url: '/how-it-works',
              isTargetBlank: false,
            },
            children: [
              {
                text: 'Our Home Buying',
              },
            ],
          },
          {
            text: ' Process Works!',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'You pay no closing costs. Get your all-cash offer started today!',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'We buy houses in Florida in cash, no matter the location, condition, or situation you are facing. Since we buy houses in cash, we can close on your property without the need for bank approvals which significantly speeds up the process.',
          },
        ],
      },
    ],
    image: {
      hashId: 'CC0nlWRpEkbV84B',
      src: 'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/1qkeo_WsMOdGo3x.webp',
      srcSet:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-1080.webp 1080w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-800.webp 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-400.webp 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-200.webp 200w',
      type: 'image/webp',
      placeholderSrc:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/placeholder/1qkeo_WsMOdGo3x.jpg',
      fallbackSrc:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/original/1qkeo_WsMOdGo3x.jpg',
      fallbackSrcSet:
        'https://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-1080.jpg 1080w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-800.jpg 800w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-400.jpg 400w,\nhttps://assets.reactbricks.com/12Wen40gwehey9E/images/src_set/1qkeo_WsMOdGo3x-200.jpg 200w',
      fallbackType: 'image/jpeg',
      height: 1042,
      width: 1080,
      alt: 'red flowers in front of blue wooden door',
      seoName: 'red-flowers-in-front-of-blue-wooden-door-slabhtobe1m',
      crop: {
        x: 0,
        y: 0,
        width: 1080,
        height: 1042,
      },
      transform: {
        rotate: 0,
        flip: {
          horizontal: false,
          vertical: false,
        },
      },
    },
    imagePosition: 'right',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    sectionLayoutSideGroup,
    {
      name: 'image',
      label: 'Image',
      type: types.SideEditPropType.Image,
    },
    {
      name: 'imagePosition',
      label: 'Image Position',
      helperText: 'On mobile, the image is always on top.',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ],
      },
    },
  ],
}

export default TextImage
